import React, { useState } from 'react';
import { Accordion, Alert, Card, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { sqWorkbenchStore } from '@/core/core.stores';
import { toBase64 } from '@/utilities/utilities';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { FrequencyEnum, SeverityEnum, SupportRequestInputV1 } from '@/sdk/model/SupportRequestInputV1';
import { AttachmentInputV1 } from '@/sdk/model/AttachmentInputV1';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { Icon } from '@/core/Icon.atom';
import { sqLogsApi } from '@/sdk';
import { successToast } from '@/utilities/toast.utilities';
import { extractError } from '@/requests/axios.utilities';

enum ReasonOption {
  Problem = 'Problem',
  Feature = 'Feature',
  Other = 'Other',
}

interface SupportRequestDetails {
  reason: keyof typeof ReasonOption;
  summary: string;
  description: string;
}

interface SupportRequestModalProps {
  onClose: () => void;
  defaultActiveKey?: '0' | '1';
  details?: Partial<SupportRequestDetails>;
}

export const SupportRequestModal: React.FunctionComponent<SupportRequestModalProps> = ({
  onClose,
  defaultActiveKey = '0',
  details = {},
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState(
    `${sqWorkbenchStore.currentUser.firstName ?? ''} ${sqWorkbenchStore.currentUser.lastName ?? ''}`.trim(),
  );
  const [email, setEmail] = useState(sqWorkbenchStore.currentUser.email);
  const [summary, setSummary] = useState(details.summary ?? '');
  const [description, setDescription] = useState(details.description ?? '');
  const [stepsToReproduce, setStepsToReproduce] = useState('');
  const [reason, setReason] = useState(details.reason ? ReasonOption[details.reason] : undefined);
  const [frequency, setFrequency] = useState(FrequencyEnum.NotApplicable);
  const [severity, setSeverity] = useState(SeverityEnum.S4);
  const [file, setFile] = useState<File>();
  const reasonOptions = [
    { label: 'SUPPORT_REQUEST.REASON_OPTIONS.PROBLEM', value: ReasonOption.Problem },
    { label: 'SUPPORT_REQUEST.REASON_OPTIONS.FEATURE', value: ReasonOption.Feature },
    { label: 'SUPPORT_REQUEST.REASON_OPTIONS.OTHER', value: ReasonOption.Other },
  ];
  const descriptionPlaceholders = {
    [ReasonOption.Problem]: 'SUPPORT_REQUEST.OBSERVED_PLACEHOLDER',
    [ReasonOption.Feature]: 'SUPPORT_REQUEST.FEATURE_PLACEHOLDER',
    [ReasonOption.Other]: 'SUPPORT_REQUEST.DESCRIPTION_PLACEHOLDER',
  };
  const frequencyOptions = [
    { label: t('SUPPORT_REQUEST.FREQUENCY_OPTIONS.NA'), value: FrequencyEnum.NotApplicable },
    { label: t('SUPPORT_REQUEST.FREQUENCY_OPTIONS.ONCE'), value: FrequencyEnum.Once },
    { label: t('SUPPORT_REQUEST.FREQUENCY_OPTIONS.INFREQUENTLY'), value: FrequencyEnum.Infrequently },
    { label: t('SUPPORT_REQUEST.FREQUENCY_OPTIONS.EVERY_TIME'), value: FrequencyEnum.EveryTime },
  ];
  const severityOptions = [
    { label: t('SUPPORT_REQUEST.SEVERITY_OPTIONS.S4'), value: SeverityEnum.S4 },
    { label: t('SUPPORT_REQUEST.SEVERITY_OPTIONS.S3'), value: SeverityEnum.S3 },
    { label: t('SUPPORT_REQUEST.SEVERITY_OPTIONS.S2'), value: SeverityEnum.S2 },
    { label: t('SUPPORT_REQUEST.SEVERITY_OPTIONS.S1'), value: SeverityEnum.S1 },
  ];

  const closeModal = () => {
    onClose();
  };

  const sendSupportRequest = async () => {
    let attachment: AttachmentInputV1 | undefined;
    if (file) {
      const content = await toBase64(file);
      attachment = { content, filename: file.name, type: file.type };
    }

    setErrorMessage('');
    const supportRequest: SupportRequestInputV1 = {
      name,
      email,
      summary,
      description,
      frequency,
      severity,
      stepsToReproduce,
      attachment,
    };
    try {
      const response = await sqLogsApi.send(supportRequest);
      successToast({
        messageKey: 'SUPPORT_REQUEST.SENT',
        buttonLabelKey: 'SUPPORT_REQUEST.VIEW_REQUEST',
        buttonAction: () => window.open(response.data.issueUrl),
        buttonIcon: 'fa-external-link',
      });
      closeModal();
    } catch (e) {
      setErrorMessage(extractError(e).message);
    }
  };

  const isReasonSelected = !_.isUndefined(reason);
  const formDefinition: FormElement[] = [
    {
      component: 'FormRow',
      name: 'nameAndEmailRow',
      extraClassNames: 'flexSpaceBetween',
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'name',
          label: 'USER.NAME',
          value: name,
          required: true,
          onChange: (value) => setName(value as string),
          size: 'md',
          testId: 'name',
          wrapperClassNames: 'flexBasis100Percent',
        },
        {
          component: 'FormControlFormComponent',
          name: 'email',
          label: 'USER.EMAIL',
          value: email,
          required: true,
          onChange: (value) => setEmail(value as string),
          size: 'md',
          testId: 'email',
          wrapperClassNames: 'flexBasis100Percent ml10',
        },
      ],
    },
    {
      component: 'FormControlFormComponent',
      name: 'summary',
      label: 'SUPPORT_REQUEST.SUMMARY',
      placeholder: 'SUPPORT_REQUEST.SUMMARY_PLACEHOLDER',
      value: summary,
      required: true,
      onChange: (value) => setSummary(value as string),
      size: 'md',
      testId: 'requestSummary',
    },
    {
      component: 'SelectFormComponent',
      name: 'selectReason',
      label: 'SUPPORT_REQUEST.REASON',
      value: reason?.toString() || '',
      onChange: (value) => setReason(ReasonOption[value as keyof typeof ReasonOption]),
      options: reasonOptions,
      required: true,
      insideModal: true,
      testId: 'selectReason',
    },
    {
      component: 'SelectFormComponent',
      includeIf: reason === ReasonOption.Problem,
      name: 'selectSeverity',
      label: 'SUPPORT_REQUEST.SEVERITY',
      value: severity.toString(),
      onChange: (value) => setSeverity(SeverityEnum[value as keyof typeof SeverityEnum]),
      options: severityOptions,
      required: true,
      insideModal: true,
    },
    {
      component: 'FormRow',
      includeIf: isReasonSelected,
      name: 'frequencyAndTimeRow',
      extraClassNames: 'flexSpaceBetween mb8',
      components: [
        {
          component: 'SelectFormComponent',
          includeIf: reason === ReasonOption.Problem,
          name: 'selectFrequency',
          label: 'SUPPORT_REQUEST.FREQUENCY',
          value: frequency.toString(),
          onChange: (value) => setFrequency(FrequencyEnum[value as keyof typeof FrequencyEnum]),
          options: frequencyOptions,
          required: true,
          insideModal: true,
          wrapperClassNames: 'flexBasis100Percent mr10',
        },
        {
          component: 'FileDropperFormComponent',
          value: file?.name,
          filename: file?.name,
          name: 'fileUploadInput',
          id: 'fileUploadInput',
          text: 'SUPPORT_REQUEST.SCREENSHOT',
          actionText: 'SUPPORT_REQUEST.SCREENSHOT_ATTACHED',
          fileExtension: '.png,.jpg,.gif,.webp',
          fileDropCallback: setFile,
          clearFileCallback: () => {
            setFile(undefined);
          },
          extraClassNames: 'flexBasis100Percent',
          validation: () => false,
        },
      ],
    },
    {
      component: 'FormControlFormComponent',
      includeIf: isReasonSelected,
      name: 'description',
      label: reason === ReasonOption.Problem ? 'SUPPORT_REQUEST.OBSERVED' : 'SUPPORT_REQUEST.DESCRIPTION',
      placeholder: isReasonSelected ? descriptionPlaceholders[reason] : '',
      value: description,
      required: true,
      onChange: (value) => setDescription(value as string),
      size: 'md',
      testId: 'requestDescription',
      as: 'textarea',
      rows: 2,
    },
    {
      component: 'FormControlFormComponent',
      includeIf: reason === ReasonOption.Problem,
      name: 'stepsToReproduce',
      label: 'SUPPORT_REQUEST.STEPS_TO_REPRODUCE',
      placeholder: 'SUPPORT_REQUEST.STEPS_TO_REPRODUCE_PLACEHOLDER',
      value: stepsToReproduce,
      required: false,
      onChange: (value) => setStepsToReproduce(value as string),
      size: 'md',
      testId: 'requestStepsToReproduce',
      as: 'textarea',
      rows: 2,
    },
  ];

  return (
    <Modal show={true} onHide={closeModal} animation={false} scrollable={true} data-testid="supportRequestModal">
      <Modal.Header closeButton={true}></Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={defaultActiveKey}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <h3 className="cursorPointer m0">
                <Icon icon="fa-search" extraClassNames="mr5" />
                {t('SUPPORT_REQUEST.HELP_YOURSELF')}
              </h3>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="text-center p10">
                <p className="mt0 mb10">
                  <em>{t('SUPPORT_REQUEST.SEARCH_KB_HELP')}</em>
                </p>
                <a
                  href="https://support.seeq.com/"
                  target="_blank"
                  className="sq-btn sq-btn-theme inlineBlock link-no-underline">
                  <Icon icon="fa-external-link" extraClassNames="mr5" type="white" />
                  {t('SUPPORT_REQUEST.SEARCH_KB_BUTTON')}
                </a>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <h3 className="cursorPointer m0">
                <Icon icon="fa-support" extraClassNames="mr5" />
                {t('SUPPORT_REQUEST.LET_US_HELP')}
              </h3>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <div className="p10">
                {errorMessage && (
                  <Alert className="p10" transition={false} variant="danger">
                    {errorMessage}
                    <a
                      href="https://seeq.atlassian.net/servicedesk/customer/portal/3"
                      target="_blank"
                      className="displayBlock mt8">
                      {t('SUPPORT_REQUEST.OPEN_PORTAL')}
                    </a>
                  </Alert>
                )}
                <SimpleSaveFormBuilder
                  formDefinition={formDefinition}
                  submitFn={sendSupportRequest}
                  closeFn={closeModal}
                  submitBtnLabel="SUPPORT_REQUEST.SEND"
                />
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
